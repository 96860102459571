:root {
	--gray1: #f7f7f8;
	--gray2: #f1f1f2;
	--gray3: #e7e7e8;
	--gray4: #d8d8d9;
	--gray5: #b4b4b5;
	--gray6: #a0a0a1;
	--gray7: #7d7d7e;
	--gray8: #6a6a6b;
	--gray9: #4d4d4e;
	--gray10: #39393a;
	--gray11: #272728;
	--gray12: #1a1a1b;

	--page-top: 100px;

	--font-sans: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	--bg: transparent;
	--cmdk-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);

	--layer-portal: 100000;

	--radix-popover-content-transform-origin: bottom center;

	--transition-fast: 0.2s;

	--radix-popover-content-transition-duration: var(--transition-fast);
	--radix-popover-content-transition-timing-function: cubic-bezier(
		0.25,
		0.1,
		0.25,
		1
	);

	--radix-popover-content-border-radius: 6px;

	--radix-popover-content-max-height: 500px;

	--radix-popover-content-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

	--radix-popover-content-out-of-boundaries-padding: 8px;

	--radix-popover-content-arrow-width: 8px;

	--radix-popover-content-arrow-color: var(--gray2);

	--radix-popover-content-arrow-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

	--radix-popover-content-arrow-background-clip: padding-box;

	--radix-popover-content-arrow-margin: 6px;

	--radix-popover-content-background: var(--gray2);

	--radix-popover-content-border: 1px solid var(--gray6);

	--radix-popover-content-padding: 12px;

	--radix-popover-content-color: var(--gray12);

	--radix-popover-content-font-size: 14px;

	--radix-popover-content-font-weight: 400;

	--radix-popover-content-line-height: 20px;

	--radix-popover-content-z-index: var(--layer-portal);

	--radix-popover-content-animation-duration: var(--transition-fast);

	--radix-popover-content-animation-timing-function: cubic-bezier(
		0.25,
		0.1,
		0.25,
		1
	);

	--radix-popover-content-animation-fill-mode: forwards;

	--radix-popover-content-arrow-width: 10px;

	--radix-popover-content-arrow-height: 5px;

	--radix-popover-content-arrow-background: var(--gray2);

	--radix-popover-content-arrow-border-color: var(--gray6);

	--radix-popover-content-arrow-border-width: 1px;

	--radix-popover-content-arrow-transform: translateY(-50%) rotate(45deg);

	--radix-popover-content-arrow-transition-property: transform;

	--radix-popover-content-arrow-transition-duration: var(--transition-fast);

	--radix-popover-content-arrow-transition-timing-function: cubic-bezier(0);
}
